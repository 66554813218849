.company-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 30px;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px 0;

    &--active {
      background-color: #f0f4ff;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 12px;
  }
}
